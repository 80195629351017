<template>
  <router-link
    class="offering-link"
    :to="{ name: 'Offering', params: { id: offering.id } }"
  >
    <div class="card shadow-sm h-100">
      <img
        v-if="offering.logoURL"
        :src="offering.logoURL"
        class="bd-placeholder-img card-img-top"
        max-height="200"
        max-width="200"
        width="100%"
      />
      <div class="card-body">
        <h3 class="card-title">{{ offering.name }}</h3>
        <p class="card-text">
          {{ offering.description }}
        </p>

        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            :style="`width: ${raisePercent}%`"
            :aria-valuenow="offering.amountRaised"
            :aria-valuemin="0"
            :aria-valuemax="offering.raiseAmount"
          ></div>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <p>
            <span class="listing-stats">${{ amountRaisedPretty }}</span
            ><br /><small class="text-muted">Raised</small>
          </p>
          <p>
            <span class="listing-stats">{{ investorCountPretty }}</span
            ><br /><small class="text-muted">Investors</small>
          </p>
          <p>
            <span class="listing-stats">${{ offering.minInvestment }}</span
            ><br /><small class="text-muted">Min Invest</small>
          </p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { computed } from "@vue/runtime-core";

export default {
  props: ["offering"],
  setup(props) {
    const prettyNumbers = uglyNumber => {
      if (uglyNumber >= 1000000) {
        return Math.round((uglyNumber / 1000000) * 10) / 10 + "M";
      }
      if (uglyNumber > 1000) {
        return Math.round((uglyNumber / 1000) * 10) / 10 + "K";
      } else {
        return uglyNumber;
      }
    };

    const amountRaisedPretty = computed(() => {
      if (props.offering) {
        return prettyNumbers(props.offering.amountRaised);
      } else {
        return null;
      }
    });

    const investorCountPretty = computed(() => {
      if (props.offering) {
        return prettyNumbers(props.offering.investorCount);
      } else {
        return null;
      }
    });

    const raisePercent = computed(() => {
      if (props.offering.raiseAmount > 0) {
        let percent =
          (100 * props.offering.amountRaised) / props.offering.raiseAmount;
        return percent;
      } else {
        return 0;
      }
    });

    return { amountRaisedPretty, investorCountPretty, raisePercent };
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>